import React from 'react';
import './App.css';

function App() {
	return (
		<div className="App">
			<header className="App-header">BeCaUsE...</header>
		</div>
	);
}

export default App;
